html {
  height: 100%;
  width: 100%;
  background-color: #050523;
}

body {
  margin: 0;
  padding: 0;
  color: #ded6c0;
  font-family: 'Beaufort W01 Bold1339640', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

strong,
b {
  /* color: #fff; */
  font-weight: 800;
}

p,
ul,
li,
strong,
b {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  list-style-position: inside;
}

@font-face {
  font-family: 'Beaufort W01 Bold1339640';
  src: url('../assets/Fonts/1339640/de0c3102-6880-4403-9f57-b10402305c4d.woff2') format('woff2'),
    url('../assets/Fonts/1339640/db16c1ca-02e1-440f-ba47-2e52b0f4c580.woff') format('woff');
  font-display: swap;
}

/*
        This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts:  http://www.fonts.com
        */
